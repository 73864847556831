import React, {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../../Websocket/WebsocketContext";
import './Modal.css'
import './SetNameModal.css'
import {HowlServiceContext, SoundKey} from "../../HowlerService/HowlServiceContext";

export const SetNameModal = () => {

    const context = useContext(WebSocketContext)!
    const {errorMessage, sendMessage, setErrorMessage} = context

    const [playerName, setPlayerName] = useState("")
    const [loading, setLoading] = useState(false)

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext

    const requestPlayerName = (e : React.FormEvent)=>{
        e.preventDefault()
        playSound(SoundKey.click,.2)
        if(playerName.length >= 3){
            const url = window.location.href
            const regex_g = /\/g\/(.*)/
            const regex_i = /\/i\/(.*)/
            const regExRedirectURL = url.match(regex_g) || url.match(regex_i)
            let redirectURL = ""
            if (regExRedirectURL) {
                redirectURL = regExRedirectURL[0]
            }
            sendMessage('setPlayerName',{playerName, redirectURL})
            setLoading(true)
        }else{
            setErrorMessage('Spielername zu kurz.')
        }
    }
    useEffect(() => {
        if(loading){
            setLoading(false)
        }
    }, [errorMessage]);

    return (
                    <div className="position-absolute bottom-80 col-10 col-sm-10 col-lg-6 col-xl-5 col-xxl-4 start-50 translate-middle-x">
                       <div className="row px-3 pb-2 inter-400 text-danger d-flex justify-content-center">{errorMessage? errorMessage: null}</div>
                        <form className="row d-flex justify-content-center" onSubmit={requestPlayerName}>
                            <div className="col-xxl-6 col-xl-8 col-lg-9 col-sm-8">
                                <input type="text" className="input-form--main inter-400" id="inputField" placeholder="Wie ist dein Spielername?" onChange={(e)=>setPlayerName(e.target.value)}/>
                            </div>
                          {/*  <div onClick={()=>playSound(SoundKey.click, .2)} className={`custom-btn btn-go d-flex justify-content-center py-2`}></div>*/}
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div onClick={requestPlayerName} className={`custom-btn btn-go mt-5`}></div>
                            </div>
                        </form>
                    </div>
    )
}