import React, {useContext, useEffect, useState} from "react";
import {RequestingClient, WebSocketContext} from "../../../../Websocket/WebsocketContext";

export const RequestingClientView = () => {

    const [rdyTimer, setRdyTimer] = useState(15)
    const [rdySent, setRdySent] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [secondLoaded, setSecondLoaded] = useState(false)

    const websocketContext = useContext(WebSocketContext)!
    const { requestingClient,setRequestingClient} = websocketContext

    useEffect(() => {
        const timer = setInterval(() => {
            if (rdyTimer > 0) {
                console.log("ok", rdyTimer)
                setRdyTimer(rdyTimer - 1);
            } else {
                if(!rdySent){
                   approveOrDeny(false)
                }
            }
        }, 1000)

        return () => clearInterval(timer)
    }, [rdyTimer]);
    const approveOrDeny = (approve: boolean)=>{
        if(requestingClient){
            const newReqClient : RequestingClient= {approvedToJoin: approve, clientID: requestingClient.clientID, name: requestingClient.name}
            setRequestingClient(newReqClient)
            setRdySent(true)
        }
    }


    return (<>
        <div className={`modal position-absolute ${(firstLoaded && secondLoaded)? '': 'd-none'}`} style={{display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-custom-content box-shd-yellow">
                    <div className="modal-body py-0">
                        <div className="row">
                            <div className={`d-flex justify-content-end`}><button onClick={() => approveOrDeny(false)} type="button" className="modal-btn-close pixer" data-bs-dismiss="modal" aria-label="Close">X</button></div>
                        </div>
                        <div className="row text-blue inter-700">
                            <div className={`d-flex justify-content-center mb-3`}>{requestingClient?.name.toUpperCase()} MÖCHTE DEINER LOBBY BEITRETEN</div>
                        </div>
                        <div className="d-flex justify-content-center pb-5">
                            <div><button className={`btn`} onClick={() => approveOrDeny(true)}><img onLoad={()=>setFirstLoaded(true)} src="/assets/pngs/icons/yes.png" alt="" style={{width:"80px"}}/></button></div>
                            <div><button className={`btn`} onClick={() => approveOrDeny(false)}><img onLoad={()=>setSecondLoaded(true)} src="/assets/pngs/icons/no.png" alt="" style={{width:"90px"}}/></button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}