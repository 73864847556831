import './ReadyClientComp.css'
import React from "react";

export enum Color{
    white = 'white',
    blue = 'blue',
}
interface Props {
    name: string
    rdyState: boolean | undefined
    color: Color
}

export const ReadyClientComp = ({name, rdyState, color}: Props) => {

    const getRdyState = (rdyState: boolean | undefined) => {
        if (rdyState === true) {
            return 'ready.png'
        }
        if (rdyState === false) {
            return 'not_ready.png'
        } else {
            return 'pending.png'
        }
    }

    return (<>
        <div className={`position-relative mx-3 pixer fs-5 px-2 py-1 ready-border border-color-${color}`}>
            <div className="px-1">{name.toUpperCase()}</div>
            <div className="position-absolute top-100 start-100 translate-middle">
                <img src={`/assets/pngs/icons/ready_check/${getRdyState(rdyState)}`} alt="" style={{width:"25px"}}/>
            </div>
        </div>
    </>)
}