import './RequestButton.css'
import '../button.css'
import {useContext} from "react";
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";

export const RequestButton = () => {

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext

    return(<div onClick={()=>playSound(SoundKey.click, .2)} className={`custom-btn btn--request-lobby d-flex justify-content-center py-2`}></div>)
}