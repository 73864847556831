import React, {useContext, useEffect, useState} from "react";
import {ReadyCheckView} from "../ReadyCheckView/ReadyCheckView";
import {WebSocketContext} from "../../../Websocket/WebsocketContext";
import {GameStartingView} from "../GameStartingView/GameStartingView";
import moment from "moment";
import './LobbyView.css'
import {LeaveLobbyView} from "../LeaveLobbyView/LeaveLobbyView";
import {TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";
import {RequestingClientView} from "./RequestingClientView/RequestingClientView";
import {Sprite, Stage} from "@pixi/react";
import {PixelateFilter} from "pixi-filters";
import * as PIXI from "pixi.js";
import {ColorMatrixFilter} from "pixi.js";
import {LoadingView} from "../../LoadingView/LoadingView";
import {LeaveGameButton} from "../../../Components/Buttons/LeaveGameButton/LeaveGameButton";
import {StartGameButton} from "../../../Components/Buttons/StartGameButton/StartGameButton";


export const LobbyView = () => {


    const context = useContext(WebSocketContext)!

    const {
        nexusGameView,
        sendMessage,
        clientID,
        relatedNexus,
        rdyCheck,
        gameStartingEvent,
        client,
        requestingClient
    } = context
    const [timeToStart, setTimeToStart] = useState(-1)
    const [leaveLobbyView, setLeaveLobbyView] = useState(false)
    const [textureImg1, setTextureImg1] = useState<PIXI.Texture | null>(null)
    const [textureImg2, setTextureImg2] = useState<PIXI.Texture | null>(null)

    const gameShareTitle = `Wiki-Streaks - Finde die Verbindung!`
    const gameShareURL = `www.wiki-streaks.de/i/${nexusGameView?.nexusID}`
    const bad_image_pink = "/assets/pngs/bad_image/bad_image_pink.png"

    useEffect(() => {
        loadTexture1().then()
        loadTexture2().then()
    }, [])

    useEffect(() => {
        if (nexusGameView && nexusGameView.isPublic) {
            const interval = setInterval(() => {
                const targetDate = moment(nexusGameView.createdAt, 'YYYY-MM-DD HH:mm:ss')
                const currentTime = moment()
                const timeDifferenceSec = Math.floor(targetDate.diff(currentTime, 'seconds'))
                const timeToStart = timeDifferenceSec + 60
                setTimeToStart(timeToStart)
                if (timeToStart < 0) {
                    clearInterval(interval)
                }
            }, 1000)
        }
    }, [nexusGameView])
    const leaveLobby = () => {
        setLeaveLobbyView(true)
    }
    const startGame = (nexusID: string) => {
        sendMessage('readyCheck', {nexusID})
    }

    const loadTexture1 = async () => {
        try {
            const text = await PIXI.Texture.fromURL(nexusGameView?.startTopic.thumbnail ? nexusGameView?.startTopic.thumbnail.source : bad_image_pink)
            setTimeout(() => setTextureImg1(text), 500)
        } catch (error) {
            console.error("Error while loading texture1:", error)
        }
    }
    const loadTexture2 = async () => {
        try {
            const text = await PIXI.Texture.fromURL(nexusGameView?.endTopic.thumbnail ? nexusGameView?.endTopic.thumbnail.source : bad_image_pink)
            setTimeout(() => setTextureImg2(text), 500)
        } catch (error) {
            console.error("Error while loading texture2:", error)
        }
    }

    return (
        <>
            {leaveLobbyView ? <LeaveLobbyView setLeaveLobbyView={setLeaveLobbyView}/> : null}
            {rdyCheck ? <ReadyCheckView nexusGameView={nexusGameView!}/> : null}
            {requestingClient ? <RequestingClientView/> : null}
            {gameStartingEvent.starting ? <GameStartingView nexusGameView={nexusGameView!}/> : null}
            {(textureImg1 && textureImg2) ? <div className={`min-vh-100 vh-100 overflow-auto`}>
                <div className="p-3">
                    <div className="col-2 c_l_logo">
                        <img src="/assets/pngs/logo/logo.png" alt="logo" style={{height: '25px'}}/>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-2">
                        <div
                            className={`col-3 fs-3 text-${nexusGameView?.isPublic ? 'pink' : 'yellow'} pixer p-0`}>DEINE
                            LOBBY:
                        </div>
                        <div
                            className="col-2 inter-400 fs-5 d-flex align-items-center">{nexusGameView?.timer || nexusGameView?.isPublic ? <>
                                <img
                                    className={`h-75`}
                                    src={`/assets/pngs/icons/TIMER_${nexusGameView.isPublic ? 'pink' : 'yellow'}.png`}
                                    alt=""/><span>&nbsp;&nbsp;{nexusGameView?.timer || timeToStart} sek</span></> :
                            <img className={`h-75`} src="/assets/pngs/icons/NO_TIMER_yellow.png" alt=""/>}</div>
                        <div className="col-7 fs-3 pixer"><span
                            className={`text-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}>SPIELENDE:</span><span
                            className={`mx-3`}>{nexusGameView?.connectedClients.map((cl, index, array) => {
                            const f = index !== array.length - 1 ? " - " : ""
                            if (cl.name === client?.name) {
                                return "Du" + f
                            } else {
                                return cl.name + f
                            }
                        })}</span></div>

                    </div>
                    {nexusGameView?.hostID === clientID ? <div className="row">
                        <div className="col-3 p-0"></div>
                        <div className="col-2"></div>
                        <div className="col-7 px-2">
                            <div className="d-flex flex-row">
                                <div className="px-2 fs-5 d-flex align-items-center">Freund*innen einladen:</div>
                                <div className="px-2"><WhatsappShareButton url={gameShareURL}
                                                                           title={gameShareTitle}><WhatsappIcon
                                    size={40} round={true}/></WhatsappShareButton></div>
                                <div className="px-2"><TelegramShareButton url={gameShareURL}
                                                                           title={gameShareTitle}><TelegramIcon
                                    size={40} round={true}/></TelegramShareButton></div>
                            </div>
                        </div>
                    </div> : null}
                    <div className={`container p-0 ${nexusGameView?.hostID === clientID ? 'mt-3' : 'mt-5'}`}>
                        <div
                            className={`row border-${nexusGameView?.isPublic ? 'pink' : 'yellow'} fp-3 inter-400 fs-6`}>
                            <div
                                className={`col-4 p-4 border-right-${nexusGameView?.isPublic ? 'pink' : 'yellow'} `}>{nexusGameView?.startTopic.title}</div>
                            <div
                                className={`col-4 p-4 ${nexusGameView?.isPublic ? 'arrow-pink-right' : 'arrow-yellow-right'}`}></div>
                            <div
                                className={`col-4 p-4 border-left-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}>{nexusGameView?.endTopic.title}</div>
                        </div>
                        <div className="row inter-400" style={{minHeight: "20rem"}}>
                            <div
                                className={`col-6 border-right-${nexusGameView?.isPublic ? 'pink' : 'yellow'} border-left-${nexusGameView?.isPublic ? 'pink' : 'yellow'} border-bottom-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}>
                                <div className="row">
                                    <div className="col-3 p-0 d-block">
                                        {textureImg1 ? <Stage
                                            className={`border-bottom-${nexusGameView?.isPublic ? 'pink' : 'yellow'} border-right-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}
                                            width={140} height={140}>
                                            <Sprite
                                                width={150}
                                                height={150}
                                                texture={textureImg1}
                                                filters={[new PixelateFilter(6), (() => {
                                                    const filter = new ColorMatrixFilter()
                                                    filter.saturate(2, true)
                                                    return filter
                                                })()]}
                                            />
                                        </Stage> : null}
                                    </div>
                                    <div className="col-9 p-2">{nexusGameView?.startTopic.extract}</div>
                                </div>
                            </div>
                            <div
                                className={`col-6 border-right-${nexusGameView?.isPublic ? 'pink' : 'yellow'} border-bottom-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}>
                                <div className="row">
                                    <div className="col-9 p-2 px-4">{nexusGameView?.endTopic.extract}</div>
                                    <div className="col-3 p-0 d-flex justify-content-end">
                                        {textureImg2 ? <Stage
                                            className={`border-left-${nexusGameView?.isPublic ? 'pink' : 'yellow'} border-bottom-${nexusGameView?.isPublic ? 'pink' : 'yellow'}`}
                                            width={140} height={140}>
                                            <Sprite
                                                width={150}
                                                height={150}
                                                texture={textureImg2}
                                                filters={[new PixelateFilter(6), (() => {
                                                    const filter = new ColorMatrixFilter()
                                                    filter.saturate(2, true)
                                                    return filter
                                                })()]}
                                            />
                                        </Stage> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="d-flex justify-content-center">
                                <div onClick={() => leaveLobby()} className={`p-2`}><LeaveGameButton/></div>
                                {nexusGameView?.hostID === clientID ?
                                    <div className={`p-2`} onClick={() => startGame(relatedNexus!)}><StartGameButton/>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <LoadingView/>}
        </>
    )
}