import './WelcomeView.css'
import {SetNameModal} from "../../Components/Modal/SetNameModal";

export const WelcomeView = ()=>{


    return <>
        <div className="video-background-holder">
            <video playsInline autoPlay muted loop>
                <source src="/assets/video/Main.mp4" type="video/mp4"/>
            </video>
            <div className="video-background-content">
                <SetNameModal/>
            </div>
        </div>

    </>
}