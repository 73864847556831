import React, {useContext, useState} from "react";
import {wikiSummary} from "wikipedia";
import {CreateLobbyInput} from "./InputField/CreateLobbyInput";
import {Link} from "react-router-dom";
import {WebSocketContext} from "../../Websocket/WebsocketContext";
import './CreateLobbyView.css'
import {LobbyCreateButton} from "../../Components/Buttons/LobbyCreateButton/LobbyCreateButton";
import {BackButton} from "../../Components/Buttons/BackButton/BackButton";


export const CreateLobbyView = () => {


    const [wikiStartSummary, setWikiStartSummary] = useState<wikiSummary>()
    const [wikiZielSummary, setWikiZielSummary] = useState<wikiSummary>()
    const [gameTimer, setGameTimer] = useState(-1)
    const [picture1Loaded, setPicture1Loaded] = useState(false)
    const [picture2Loaded, setPicture2Loaded] = useState(false)

    const context = useContext(WebSocketContext)!

    const {sendMessage} = context

    const fetchByPageID = async (pageid: number): Promise<wikiSummary | undefined> => {
        const res = await fetch(`http://${process.env.REACT_APP_WEBSOCKET_ADDRESS}:${process.env.REACT_APP_API_PORT}/searchByPageID?q=${pageid}`)

        const result: wikiSummary = await res.json()

        if (result) {
            return result
        } else {
            return undefined
        }
    }

    const createLobby = () => {
        if (wikiStartSummary && wikiZielSummary) {
            sendMessage('createNexus', {
                startTopic: wikiStartSummary.title,
                endTopic: wikiZielSummary.title,
                timer: gameTimer
            })
        }
    }


    return (
        <div className={`min-vh-100 vh-100 overflow-auto`}>
            <div className="p-3">
                <div className="col-2 c_l_logo">
                    <img src="assets/pngs/logo/logo.png" alt="logo" style={{height:'25px'}}/>
                </div>
            </div>
            <div className="container">
                <div className="row my-2">
                    <div className="col-12 fs-3 text-yellow pixer p-0">DEINE LOBBY:</div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="row border-yellow">
                            <div
                                className="col-2 border-right-yellow fs-1 text-yellow d-flex justify-content-center inter-400">START
                            </div>
                            <div className="col-8">
                                <div className="row h-100">
                                    <div className="col-2"></div>
                                    <div className="col-8 arrow-yellow-right"></div>
                                    <div className="col-2"></div>
                                </div>
                            </div>
                            <div
                                className="col-2 border-left-yellow fs-1 text-yellow d-flex justify-content-center inter-400">ZIEL
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-4 p-0">
                        <div className="col-12"><CreateLobbyInput fetchByPageID={fetchByPageID}
                                                                  setWikiSummary={setWikiStartSummary}
                                                                  showDropdown={!wikiStartSummary}/></div>
                        <div className="pixer fs-4 col-12 mt-3">{wikiStartSummary ? wikiStartSummary.title : '-'}</div>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div className="col-12"><CreateLobbyInput fetchByPageID={fetchByPageID}
                                                                  setWikiSummary={setWikiZielSummary}
                                                                  showDropdown={!wikiZielSummary}/></div>
                        <div
                            className="pixer fs-4 col-12 mt-3 justify-content-end d-flex">{wikiZielSummary ? wikiZielSummary.title : '-'}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-4 p-0">
                        <div className="col-12">Zeitliche Begrenzung</div>
                        <div className="col-12">
                            <div className="row mt-2">
                                <div className="col-2"><img src="/assets/pngs/icons/TIMER_yellow.png" alt=""/></div>
                                <div className="col-10">
                                    <input type="number" className="input-form inter-400" id="exampleFormControlInput1"
                                           placeholder="z.b.60" onChange={(e) => {
                                        setGameTimer(parseFloat(e.target.value))
                                    }} value={gameTimer === -1 ? "" : gameTimer}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div className="col-12"></div>
                        <div className="col-12">

                        </div>
                    </div>
                </div>
                <div className="row"></div>
                <div className="row mt-20">
                    <div className="col-5 arrow-right mt-4 h-40"></div>
                    <div className={`col-2 d-flex flex-column justify-content-center align-items-center`}>
                        <div className="mb-2"><Link to="/" className=""><BackButton/></Link></div>
                        <div className={`${(!wikiStartSummary || !wikiZielSummary) ? 'disabled' : null}`}><LobbyCreateButton onClick={createLobby}/></div>
                    </div>
                    <div className="col-5 arrow-left mt-4 h-40"></div>
                </div>
            </div>
        </div>
    )
}