import './../button.css'
import './GameViewSwitchButton.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {Dispatch, SetStateAction, useContext} from "react";

interface Props {
    active: boolean
    onClick: Dispatch<SetStateAction<boolean>>
}
export const ListViewButton = ({active, onClick} : Props) => {

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    const play = ()=>{
        onClick(false)
        if(!active){
            playSound(SoundKey.click, .2)
        }
    }
    return(<div onClick={play} className={`custom-btn btn-list-block-size d-flex justify-content-center py-2 ${active?'btn--list-view-active' : 'btn--list-view'}`}></div>)
}