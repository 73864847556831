import React, {useContext, useEffect, useState} from "react"
import {Nexus, WebSocketContext} from "../../Websocket/WebsocketContext"
import moment from "moment"
import './NexusComp.css'
import {EnterButton} from "../Buttons/EnterButton/EnterButton";
import {RequestButton} from "../Buttons/RequestButton/RequestButton";
import * as PIXI from "pixi.js";
import {Sprite, Stage} from "@pixi/react";
import {PixelateFilter} from "pixi-filters";
import {ColorMatrixFilter} from "pixi.js";
import {RequestedButton} from "../Buttons/RequestedButton/RequestedButton";

interface Props {
    nexus: Nexus,
}

export const NexusComp = ({nexus}: Props) => {

    const [loading, setLoading] = useState(false)
    const [timeToStart, setTimeToStart] = useState(-1)
    const context = useContext(WebSocketContext)!

    const [textureImg1, setTextureImg1] = useState<PIXI.Texture | null>(null)
    const [textureImg2, setTextureImg2] = useState<PIXI.Texture | null>(null)


    const bad_image_pink = "/assets/pngs/bad_image/bad_image_pink.png"
    const {sendMessage} = context


    useEffect(() => {

    }, []);
    useEffect(() => {
        setTimeToStart(calcTimeToStart())
        if (nexus.isPublic) {
            const interval = setInterval(() => {
                const tts = calcTimeToStart()
                setTimeToStart(tts)
                if (tts < 0) {
                    clearInterval(interval)
                }
            }, 1000)
        }
        loadTexture1().then()
        loadTexture2().then()
    }, [nexus])
    const calcTimeToStart = () => {
        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
        return moment(nexus.createdAt).diff(currentTime, 'seconds') + 60
    }
    const connectToNexus = (nexusID: string, startTopic: string) => {
        if ((nexus.closed || (nexus.isPublic && timeToStart < 3) || (nexus.currentlyRequestingToJoin))) {
            return
        }
        if (nexus.isPublic) {
            setLoading(true)
            const msg = {
                type: 'connectToNexus',
                data: {
                    nexusID
                }
            }
            sendMessage(msg.type, msg.data)
        } else {
            sendMessage('requestJoinNexus', {nexusID})
        }
    }

    const loadTexture1 = async () => {
        try {
            const text = await PIXI.Texture.fromURL(nexus.startTopic.thumbnail ? nexus.startTopic.thumbnail.source : bad_image_pink)
            setTextureImg1(text)
        } catch (error) {
            console.error("Error while loading texture1:", error)
        }
    }
    const loadTexture2 = async () => {
        try {
            const text = await PIXI.Texture.fromURL(nexus.endTopic.thumbnail ? nexus.endTopic.thumbnail.source : bad_image_pink)
            setTextureImg2(text)
        } catch (error) {
            console.error("Error while loading texture2:", error)
        }
    }

    const options = {
        backgroundAlpha: 0,
    }
    return (
        <div className="anim-fade">
            <div className="row mb-10">
                <div className={`col-10  ${nexus.isPublic ? 'border-cyan' : 'border-pink'}`}>
                        <div className="row">
                            <div
                                className="col-4 d-flex justify-content-center inter-700 p-2">{nexus.startTopic.title}</div>
                            <div className={`col-3 ${nexus.isPublic ? 'arrow-cyan-right' : 'arrow-pink-right'}`}>
                            </div>
                            <div className="col-5 d-flex justify-content-center inter-700 p-2">{nexus.endTopic.title}</div>
                        </div>
                        <div className="row cursor-mouse">
                            <div
                                className={`col-1 d-flex align-items-center justify-content-center ${nexus.isPublic ? 'border-top-cyan text-cyan' : 'border-top-pink text-pink'}`}>
                                <div className="rotate-vertical text-center inter-400 fs-2 ">START</div>
                            </div>
                            <div
                                className={`col-2  ${nexus.isPublic ? 'bg-cyan' : 'bg-pink'} square-image-container d-flex justify-content-center align-items-center ${nexus.isPublic ? 'border-cyan' : 'border-pink'}`}>
                                {textureImg1? <Stage options={options} key={textureImg1.baseTexture.uid} className={`anim-fade`} width={150} height={150}>
                                    <Sprite
                                        width={150}
                                        height={150}
                                        texture={textureImg1}
                                        filters={[new PixelateFilter(6), (()=>{
                                            const filter = new ColorMatrixFilter()
                                            filter.saturate(2,true)
                                            return filter
                                        })()]}
                                    />
                                </Stage>: null}
                            </div>
                            <div
                                className={`col-5 inter text-black p-4 ${nexus.isPublic ? 'border-cyan bg-cyan' : 'border-pink bg-pink'}`}>
                                <div
                                    className="col-12 p-1 d-flex justify-content-center align-items-center">{!nexus.isPublic ? '\u00A0\u00A0' : null}<img
                                    style={{maxHeight: '30px'}}
                                    src={`/assets/pngs/icons/${nexus.isPublic ? 'TIMER.png' : 'LOCK.png'}`}
                                    alt="icon"/>&nbsp;&nbsp;{nexus.isPublic ? timeToStart + ' sek' : null}</div>
                                <div
                                    className="col-12 p-1 d-flex justify-content-center">{nexus.connectedClient.length}/{nexus.maxPlayer} Spielern
                                </div>
                                <div
                                    className="col-12 d-flex justify-content-center inter-700">{nexus.isPublic ? 'öffentlich' : 'privat'}</div>
                            </div>
                            <div
                                className={`col-2 ${nexus.isPublic ? 'bg-cyan' : 'bg-pink'} square-image-container d-flex justify-content-center align-items-center ${nexus.isPublic ? 'border-cyan' : 'border-pink'}`}>
                                {textureImg2? <Stage options={options} key={textureImg2.baseTexture.uid} className={`anim-fade`} width={150} height={150}>
                                    <Sprite
                                        width={150}
                                        height={150}
                                        texture={textureImg2}
                                        filters={[new PixelateFilter(6), (()=>{
                                            const filter = new ColorMatrixFilter()
                                            filter.saturate(2,true)
                                            return filter
                                        })()]}
                                    />
                                </Stage>: null}
                            </div>
                            <div
                                className={`col-1 ${nexus.isPublic ? 'border-top-cyan border-right-cyan single-arrow-cyan-right' : 'border-top-pink border-right-pink single-arrow-pink-right'}`}></div>
                            <div
                                className={`col-1 d-flex align-items-center justify-content-center ${nexus.isPublic ? 'border-top-cyan text-cyan' : 'border-top-pink text-pink'}`}>
                                <div className="rotate-vertical text-center inter-400 fs-2">ZIEL</div>
                            </div>
                        </div>
                </div>

                <div className="col-2 position-relative">
                    <div className="row position-absolute top-50 end-0 translate-middle-y">
                        <div onClick={() => connectToNexus(nexus.nexusID, nexus.startTopic.title)}
                             className={`d-flex justify-content-${nexus.isPublic?'end':'center'} ${nexus.currentlyRequestingToJoin ? 'disabled' : null}`}>{nexus.isPublic ?
                            <EnterButton/> : nexus.currentlyRequestingToJoin ? <RequestedButton/> : <RequestButton/>}</div>
                        <div className="col-12 d-flex justify-content-center">
                            {!nexus.isPublic && nexus.currentlyRequestingToJoin ? 'angefragt' : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}