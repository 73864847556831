import React, {useContext, useEffect, useState} from "react";
import {NexusGameView, WebSocketContext} from "../../../Websocket/WebsocketContext";
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {Color, ReadyClientComp} from "./ReadyClientComp/ReadyClientComp";

interface Props {
    nexusGameView: NexusGameView
}
export const ReadyCheckView = ({nexusGameView}: Props) => {

    const [rdyTimer, setRdyTimer] = useState(15)
    const [rdySent, setRdySent] = useState(false)
    const [clientsReady, setClientsReady] = useState(0)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [secondLoaded, setSecondLoaded] = useState(false)
    const [hostName, setHostName] = useState("")

    const websocketContext = useContext(WebSocketContext)
    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    useEffect(() => {
        if(clientsReady > 1){
            playSound(SoundKey.readyCount, .2)
        }
    }, [clientsReady]);
    useEffect(() => {
        getHostName()
        let rdyClients = 0
        nexusGameView.connectedClients.map((cl)=>{
            if(cl.ready){
                rdyClients ++
            }
        })

        if(clientsReady!== rdyClients){
            setClientsReady(rdyClients)
        }
    }, [nexusGameView]);
    useEffect(() => {
        const thisClient = nexusGameView.connectedClients.find(cl=>cl.id ===clientID)!
        if(thisClient.ready !== undefined){
            setRdySent(true)
        }
            const timer = setInterval(() => {
                if (rdyTimer > 0) {
                    setRdyTimer(rdyTimer - 1);
                } else {
                    if(!rdySent && !thisClient.ready){
                        sendRdyState(false)
                    }
                }
            }, 1000)

            return () => clearInterval(timer)
    }, [rdyTimer, rdySent]);
    useEffect(() => {
        playSound(SoundKey.readyCheck,.5)
    }, []);
    if(!websocketContext)
        return null
    const { sendMessage, clientID, relatedNexus} = websocketContext

    const getHostName = ()=>{
        const hostID = nexusGameView.hostID
        const host = nexusGameView.connectedClients.find(cl=>cl.id === hostID)
        if(host){
            setHostName(host.name.toUpperCase())
        }
    }

    const sendRdyState = (state: boolean)=>{
        setRdySent(true)
        sendMessage('setReadyState',{nexusID: relatedNexus, readyState: state})
    }


    return (
        <div className={`modal position-absolute ${(firstLoaded && secondLoaded)? '': 'd-none'}`} style={{display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-custom-content modal-bg-blue box-shd-pink">
                    <div className="modal-body py-0">
                        <div className="row">
                            <div className={`d-flex justify-content-end`}><button onClick={() => sendRdyState(false)} type="button" className="modal-btn-close text-white pixer" data-bs-dismiss="modal" aria-label="Close">X</button></div>
                        </div>
                        <div className="row text-white inter-700">
                            <div className={`d-flex justify-content-center mb-3`}>{hostName} MÖCHTE DAS SPIEL STARTEN</div>
                        </div>
                        <div className="row mb-4">
                            <div className="d-flex justify-content-center">
                                {nexusGameView && nexusGameView.connectedClients.map((cl,key) => (
                                    <ReadyClientComp name={cl.name} rdyState={cl.ready} color={Color.white}/>
                                ))}
                            </div>
                        </div>
                        <div className="d-flex justify-content-center pb-5">
                            <div><button className={`btn`} onClick={()=>sendRdyState(true)}><img onLoad={()=>setFirstLoaded(true)} src="/assets/pngs/buttons/yes.png" alt="" style={{width:"80px"}}/></button></div>
                            <div><button className={`btn`} onClick={()=>sendRdyState(false)}><img onLoad={()=>setSecondLoaded(true)} src="/assets/pngs/buttons/no.png" alt="" style={{width:"80px"}}/></button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
