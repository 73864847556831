import {NexusComp} from "../../Components/NexusComp/NexusComp";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {WebSocketContext} from "../../Websocket/WebsocketContext";
import {WelcomeView} from "../WelcomeView/WelcomeView";
import './MainPage.css'
import {LobbyCreateButton} from "../../Components/Buttons/LobbyCreateButton/LobbyCreateButton";
import {TransitionGroup, CSSTransition} from "react-transition-group";

export const MainPage = () => {

    const context = useContext(WebSocketContext)!
    const {nexusList, client} = context

    return (
        <>
            {client?.name ? <div className={`vh-100 overflow-auto overflow-x-hidden`}>
                <div className="row w-100 p-3">
                    <div className="col-2 c_l_logo">
                        <img src="assets/pngs/logo/logo.png" alt="logo" style={{height: '25px'}}/>
                    </div>
                    <div className="col-10 walking-text-container">
                        <div className="walking-text text-uppercase">
                            Finde die Verbindung in 10 Klicks oder weniger – Finde die Verbindung in 10 Klicks oder
                            weniger – Finde die Verbindung in 10 Klicks oder weniger
                        </div>
                    </div>
                </div>
                <div className="container mt-4">
                    <div className="row mb-10">
                        <div className="col-10 border-yellow">
                            <div className="row">
                                <div className="col-8 inter px-4 p-2">Erstelle eine private Lobby und lade Freund*innen
                                    ein.
                                </div>
                                <div className="col-4 arrow-yellow-right"></div>
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <div className={`d-flex justify-content-center py-1`}>
                                <Link to={`createLobby`}
                                      className="text-decoration-none text-white"><LobbyCreateButton/></Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <TransitionGroup>
                            {nexusList ? nexusList.map((nexus, key) => (
                                <CSSTransition key={nexus.nexusID} timeout={500} classNames={`${nexus.isPublic? 'public-slide' : 'slide'}`}>
                                    <NexusComp nexus={nexus}/>
                                </CSSTransition>
                            )) : null}
                        </TransitionGroup>
                        {nexusList.length === 0 ? "Aktuell ist kein Spiel verfügbar. Erstelle ein eigenes oder warte kurz bis ein öffentliches Spiel erscheint." : null}
                    </div>
                </div>
            </div> : <WelcomeView/>}
        </>
    )
}