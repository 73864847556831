import './../button.css'
import './GameViewSwitchButton.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {Dispatch, SetStateAction, useContext} from "react";

interface Props {
    active: boolean
    onClick: Dispatch<SetStateAction<boolean>>
}
export const BlockViewButton = ({active, onClick} : Props) => {

    const play = ()=>{
        onClick(true)
        if(!active){
            playSound(SoundKey.click, .2)
        }
    }

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    return(<div onClick={play} className={`custom-btn btn-list-block-size d-flex justify-content-center py-2 ${active?'btn--block-view-active' : 'btn--block-view'}`}></div>)
}