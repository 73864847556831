import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../../../Websocket/WebsocketContext";
import './LeaveLobbyView.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";

interface Props {
    setLeaveLobbyView: Dispatch<SetStateAction<boolean>>
}

export const LeaveLobbyView = ({setLeaveLobbyView}: Props) => {

    const context = useContext(WebSocketContext)!
    const {sendMessage, nexusGameView, clientID} = context
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [secondLoaded, setSecondLoaded] = useState(false)


    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext

    const leaveLobby = () => {
        playSound(SoundKey.click, .2)

        if (nexusGameView) {
            const nexusID = nexusGameView.nexusID
            sendMessage('leaveNexus', {nexusID})
        }
    }
    const decline = ()=>{
        playSound(SoundKey.click, .2)
        setLeaveLobbyView(false)
    }


    return (
        <>
            <div className={`modal position-absolute ${(firstLoaded && secondLoaded)? '': 'd-none'}`} style={{display: 'block'}}>
                <div className="modal-dialog">
                    <div className="modal-custom-content box-shd-cyan">
                        <div className="modal-body py-0">
                            <div className="row">
                                <div className={`d-flex justify-content-end`}><button onClick={() => setLeaveLobbyView(false)} type="button" className="modal-btn-close pixer" data-bs-dismiss="modal" aria-label="Close">X</button></div>
                            </div>
                            <div className="row text-blue inter-700">
                                <div className={`d-flex justify-content-center mb-3`}>MÖCHTEST DU DIE LOBBY VERLASSEN?</div>
                            </div>
                            <div className="d-flex justify-content-center pb-5">
                                <div><button className={`btn`} onClick={() => leaveLobby()}><img onLoad={()=>setFirstLoaded(true)} src="/assets/pngs/buttons/yes.png" alt="" style={{width:"80px"}}/></button></div>
                                <div><button className={`btn`} onClick={() => decline()}><img onLoad={()=>setSecondLoaded(true)} src="/assets/pngs/buttons/no.png" alt="" style={{width:"80px"}}/></button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
