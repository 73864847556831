import React from "react";

interface HowlerServiceContextType {
    playSound: (soundKey: SoundKey, volume?: number) => void
}
export enum SoundKey {
    startingGame = 'startingGame',
    startingCounter = 'startingCounter',
    readyCheck = 'readyCheck',
    readyCount = 'readyCount',
    rockSmashing = 'rockSmashing',
    click = 'click',
    modal = 'modal',
}
export const HowlServiceContext = React.createContext<HowlerServiceContextType | undefined>(undefined)
