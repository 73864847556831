import React, {Dispatch, SetStateAction} from "react";
import {wikiSummary} from "wikipedia";

export interface WebSocketContextType {
    clientID: string
    client: Client | undefined
    websocket: WebSocket | undefined
    relatedNexus: string | null
    nexusList: Nexus[]
    nexusGameView: NexusGameView | null
    htmlGameView: string
    rdyCheck: boolean
    gameStartingEvent: GameStartingEvent
    sendMessage: (type: string, message: any) => void
    errorMessage: string,
    setErrorMessage: Dispatch<SetStateAction<string>>,
    requestingClient: RequestingClient | undefined
    setRequestingClient: Dispatch<SetStateAction<RequestingClient | undefined>>,
    roundFinished: boolean,
}

export interface RequestingClient {
    name: string
    clientID: string
    approvedToJoin: boolean | undefined
}

export interface Nexus {
    nexusID: string
    startTopic: wikiSummary
    endTopic: wikiSummary
    maxPlayer: number
    currentlyRequestingToJoin: boolean
    connectedClient: NexusClient[]
    started: boolean
    closed: boolean
    isPublic: boolean
    createdAt: string
}

interface Client {
    clientID: string
    created_at: string
    lastWin: string
    name: string
    played: number
    wins: number
}

interface NexusClient {
    id: string,
    ws: WebSocket,
    name: string,
    wins: number,
    gameInfo: { clicks: number }
}

export interface NexusGameView {
    nexusID: string
    hostID: string
    startTopic: wikiSummary
    endTopic: wikiSummary
    started: boolean
    started_at: string | null
    expires_at: string | null
    finished_at: string | null
    timer: number | null
    finished: boolean
    winner: string
    isPublic: boolean
    createdAt: string
    joinCode: string
    connectedClients: ConnectedClient[]
}

export interface ConnectedClient {
    id: string,
    name: string,
    wins: number,
    path: Path[],
    clicks: number,
    online: boolean
    ready: boolean | undefined
}

export interface Path {
    pathTitle: string
    pathImage: string
}

export interface GameStartingEvent {
    count: number
    starting: boolean
}

export const WebSocketContext = React.createContext<WebSocketContextType | undefined>(undefined)
