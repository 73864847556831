import React, {useEffect, useState} from "react"
import {Howl} from "howler"
import {HowlServiceContext, SoundKey} from "./HowlServiceContext"

export const HowlServiceProvider = ({ children }: any) => {
    const [sounds, setSounds] = useState<Record<string, Howl>>({})
    const [areSoundsLoaded, setAreSoundsLoaded] = useState(false)

    const preloadSound = (soundKey: SoundKey, soundUrl: string) => {
        setSounds((prevSounds) => ({
            ...prevSounds,
            [soundKey]: new Howl({ src: [soundUrl], preload: true, html5: false }),
        }))
    }

    const preloadAllSounds = () => {
        preloadSound(SoundKey.readyCount, "/sounds/LFG_RoleCheck.ogg")
        preloadSound(SoundKey.readyCheck, "/sounds/levelup2.ogg")
        preloadSound(SoundKey.startingCounter, "/sounds/Spell_DH_Nemesis_Cast_01.ogg")
        preloadSound(SoundKey.startingGame, "/sounds/UI_OrderHall_Talent_Ready_Check.ogg")
        preloadSound(SoundKey.rockSmashing, "/sounds/rock_smashing.mp3")
        preloadSound(SoundKey.click, "/sounds/effects/shooting.mp3")
        preloadSound(SoundKey.modal, "/sounds/effects/switch_005.ogg")
    }
    useEffect(() => {
        if (!areSoundsLoaded) {
            preloadAllSounds()
            setAreSoundsLoaded(true)
        }
    }, [areSoundsLoaded])

    const playSound = (soundKey: string, volume?: number) => {
        const sound = sounds[soundKey]
        if (sound) {
            if (volume) {
                sound.volume(.2)
            }
            sound.play()
        }
    }

    return (
        <HowlServiceContext.Provider value={{ playSound }}>
            {children}
        </HowlServiceContext.Provider>
    )
}