import React, {useContext} from 'react';
import './App.css';
import {MainPage} from "./Views/MainPage/MainPage";
import {LobbyView} from "./Views/LobbyView/LobbyView/LobbyView";
import {Route, Routes, Navigate} from "react-router-dom";
import {CreateLobbyView} from "./Views/CreateLobbyView/CreateLobbyView";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import {WebSocketContext} from "./Websocket/WebsocketContext";
import {GameView} from "./Views/GameView/GameView";
import {InvitedToLobbyView} from './Views/LobbyView/InvitedToLobbyView/InvitedToLobbyView';
import {LeaderBoardView} from "./Views/LeaderBoardView/LeaderBoardView";

function App() {

    const context = useContext(WebSocketContext)
    if (!context)
        return null

    const {relatedNexus, errorMessage, nexusGameView} = context

    return (
        <>
                {errorMessage ? <div
                        className="pixer position-absolute bottom-0 end-0 m-5 toast show align-items-center text-bg-danger border-0"
                        role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body">
                                {errorMessage}
                            </div>
                            <button type="button" className="button-close me-2 m-auto" data-bs-dismiss="toast"
                                    aria-label="Close">X</button>
                        </div>
                    </div>
                    :
                    null
                }
                <Routes>
                    <Route path="/" element={!relatedNexus ? <MainPage/> :
                        <Navigate replace to={`g/${relatedNexus}`}/>}/>
                    <Route path="g/:nexusID" element={relatedNexus ? (nexusGameView?.started ? <GameView/> : <LobbyView/>) :
                        <Navigate replace to={`/`}/>}/>
                    <Route path="i/:nexusID" element={!relatedNexus ? <InvitedToLobbyView/> :
                        <Navigate replace to={`/`}/>}/>
                    <Route path="createLobby" element={<CreateLobbyView/>}/>
                    <Route path="leaderBoard" element={<LeaderBoardView/>}/>
                    <Route
                        path="*"
                        element={
                            <div className="text-center m-5 p-5">
                                <h2>404 Site not found </h2>
                            </div>
                        }
                    />
                </Routes>
        </>
    );
}

export default App;
