import './../button.css'
import './CubeButton.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {useContext} from "react";

interface Props {
    loading: boolean
}
export const CubeButton = ({loading} : Props) => {

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    return(<div onClick={()=>playSound(SoundKey.click, .2)} className={`custom-btn btn-cube d-flex justify-content-center py-2 ${loading? 'animate-cube': null}`}></div>)
}