import React, {useEffect, useRef, useState} from "react"
import * as PIXI from "pixi.js"
import {ColorMatrixFilter, Resource, Texture} from "pixi.js"
import "@pixi/events"
import {AnimatedSprite, Container, Sprite, useTick} from "@pixi/react"
import {SoundKey} from "../../../../HowlerService/HowlServiceContext"
import {Path} from "../../../../Websocket/WebsocketContext"
import {PixelateFilter} from "pixi-filters"

interface Props {
    posX: number
    posY: number
    posFrom: number
    height: number
    padding: number
    playSound: (soundKey: SoundKey, volume?: number) => void
    path: Path
}

export const Block = ({posX, posY, posFrom, height, padding, playSound, path}: Props) => {

    const x = posX
    const y = posY - 35
    const from = posFrom

    const animatedFrames = [
        PIXI.Texture.from("/assets/pngs/block_animation/0.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/1.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/2.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/3.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/4.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/5.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/6.png"),
        PIXI.Texture.from("/assets/pngs/block_animation/7.png"),
    ]

    const centerAnchor = new PIXI.Point(0.5, 0.5)
    const spriteRef = useRef<PIXI.Sprite | null>(null)
    const blockSpriteRef = useRef<PIXI.Sprite>(null)
    const animatedSpriteRef = useRef<PIXI.AnimatedSprite | null>(null)
    const [animationFinished, setAnimationFinished] = useState(false)
    const [frames, setFrames] = useState<Texture<Resource>[]>(animatedFrames)
    const [soundPlayed, setSoundPlayed] = useState(false)
    const [filter, setFilter] = useState<PIXI.Filter[]>([])

    const [texture, setTexture] = useState<PIXI.Texture | null>(null)
    const [textureLoaded, setTextureLoaded] = useState(false)
    useEffect(() => {
        let texture = path.pathImage ?? ""
        if (texture.length < 1) {
            texture = "/assets/pngs/bad_image/bad_image_pink.png"
            setFilter([])
        }else{
            setFilter([new PixelateFilter(6), (()=>{
                const filter = new ColorMatrixFilter()
                filter.saturate(2,true)
                return filter
            })()])
        }
        const loadTexture = async () => {
            try {
                const text = await PIXI.Texture.fromURL(texture)
                setTexture(text)
                setTextureLoaded(true)
            } catch (error) {
                console.error("Error while loading texture:", error)
            }
        }

        loadTexture().then()
        startAnimation()
    }, [])

    const startAnimation = () => {
        if (spriteRef.current) {
            spriteRef.current.position.set(x, from)
        }
    }

    useTick((delta) => {
        if (spriteRef.current && spriteRef.current.y > y) {
            setAnimationFinished(true)
            if (!soundPlayed) {
                setSoundPlayed(true)
                playSound(SoundKey.rockSmashing, 1)
            }
        } else {
            if (spriteRef.current) {
                spriteRef.current.position.set(x, spriteRef.current.y + delta * 10)
            }
        }
    })
    return (<>
        {(textureLoaded && texture) && <Container ref={spriteRef} x={x} y={from} width={100} height={100}>
            <Sprite
                width={height}
                height={height}
                anchor={centerAnchor}
                texture={texture}
                mask={blockSpriteRef.current}
                filters={filter}
            />
            <AnimatedSprite
                ref={animatedSpriteRef}
                width={200}
                height={150}
                anchor={centerAnchor}
                textures={frames}
                isPlaying={animationFinished}
                animationSpeed={0.2}
                onComplete={() => animatedSpriteRef.current!.visible = false}
                loop={false}
            />
        </Container>}</>)
}