import {useContext, useEffect} from "react";
import {WebSocketContext} from "../../../Websocket/WebsocketContext";
import {useNavigate} from "react-router-dom";
import {SetNameModal} from "../../../Components/Modal/SetNameModal";

export const InvitedToLobbyView = () => {

    const context = useContext(WebSocketContext)!
    const {sendMessage, relatedNexus, client, errorMessage} = context
    const navigate = useNavigate()

    useEffect(() => {
        if (!relatedNexus && client?.name) {
            const url = window.location.href
            const regex_i = /\/i\/(.*)/
            const redirectURL = url.match(regex_i)
            if (redirectURL) {
                const nexusID = redirectURL[1]
                const joinCode = nexusID.slice(-4).toUpperCase()
                sendMessage("requestJoinNexusWithJoinCode", {nexusID, joinCode});
            }
        }
    }, [relatedNexus, client]);

    useEffect(() => {
        if(errorMessage){
            navigate('/')
        }
    }, [errorMessage]);

    return (<>{client?.name? <div className="d-flex justify-content-center">
        <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div> : <SetNameModal/>}</>)
}