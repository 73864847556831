import React from "react"
import * as PIXI from "pixi.js"
import "@pixi/events"
import {Container, Text} from "@pixi/react"

interface Props {
    name: string
    posX: number
    posY: number
    width: number
    color: string
}

export const Name = ({name,posX, posY, width, color}: Props) => {


    return (<>
        {<Container x={posX -25} y={posY} width={width -25} height={20}>
            <Text
            text={name}
            resolution={5}
            style={new PIXI.TextStyle({
                fontFamily: 'pixer',
                fontSize: 25,
                fill: color,
            })}
            />
        </Container>}</>)
}