import React from "react"
import * as PIXI from "pixi.js"
import "@pixi/events"
import { Container,  Text} from "@pixi/react"

interface Props {
    name: string
    posX: number
    posY: number
    width: number
    color: string
}

export const PathName = ({name,posX, posY, width, color}: Props) => {



    return (<>
        {<Container x={posX -25} y={posY} width={width -25} height={20}>
            <Text
                text={name.replace(/_/g, ' ')}
                resolution={2}
                style={new PIXI.TextStyle({
                    fontFamily: 'Inter',
                    fontSize: 15,
                    fill: color,
                })}
            />
        </Container>}</>)
}