import React, {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../../../Websocket/WebsocketContext";
import moment from "moment";

export const GameFinishedView = ()=>{
    const context = useContext(WebSocketContext)!

    const {sendMessage, nexusGameView, clientID} = context
    const [winnerStreaks, setWinnerStreaks] = useState(0)
    const [gameDuration, setGameDuration] = useState(0)

    const leaveLobby = () => {
        const nexusID = nexusGameView?.nexusID
        if(nexusID)
            sendMessage('leaveNexus', {nexusID})
    }

    useEffect(() => {
        console.log(nexusGameView)
        const winner_client = nexusGameView?.connectedClients.find(cl=>cl.id===nexusGameView.winner)
        if(winner_client && nexusGameView?.winner === clientID){
            const streaks = winner_client.path.length
            setWinnerStreaks(streaks)
        }

        const targetDate = moment(nexusGameView?.started_at, 'YYYY-MM-DD HH:mm:ss')
        const currentTime = moment(nexusGameView?.finished_at, 'YYYY-MM-DD HH:mm:ss')
        const gameDuration = Math.floor(currentTime.diff(targetDate, 'seconds'))
        setGameDuration(gameDuration)
    }, []);
    return <div className={`modal position-absolute`} style={{display: 'block'}}>
        <div className="modal-dialog">
            <div className={`modal-custom-content ${nexusGameView?.winner === null || nexusGameView?.winner.length === 1?'modal-bg-red' : 'modal-bg-pink'} box-shd-yellow`}>
                <div className="modal-body py-0">
                    <div className="row">
                        <div className={`d-flex justify-content-end`}><button onClick={() => leaveLobby()} type="button" className="modal-btn-close text-white pixer" data-bs-dismiss="modal" aria-label="Close">X</button></div>
                    </div>
                    <div className="row fs-5 inter-700">
                        <div className={`d-flex justify-content-center mb-3`}>{nexusGameView?.winner === null || nexusGameView?.winner !== clientID?'DU HAST VERLOREN :\'(' : 'DU HAST GEWONNEN!'}</div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        {nexusGameView?.winner === null || nexusGameView?.winner !== clientID?<img src="/assets/pngs/icons/loose.png" alt="logo" style={{width: '110px'}}/> :<img src="/assets/pngs/icons/winner.png" alt="logo" style={{width: '110px'}}/> }

                    </div>
                    <div className="row mt-2 fs-5 inter-700 d-flex justify-content-center">
                       WIKI STREAKS: {winnerStreaks> 0? winnerStreaks: '--'}
                    </div>
                    <div className="row pb-5 mt-2 fs-5 inter-700 d-flex justify-content-center">
                        <img src={`/assets/pngs/icons/TIMER_yellow.png`} alt="" style={{width:'55px'}}/>{gameDuration} sek
                    </div>
                   {/* <div className="row pb-3 d-flex justify-content-center">
                        <div onClick={() => leaveLobby()} className="col-5 d-flex justify-content-center"><LeaveGameButton/></div>
                    </div>*/}
                </div>
            </div>
        </div>
    </div>

}