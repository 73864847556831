import React, {useEffect, useState} from "react"
import {GameStartingEvent, Nexus, NexusGameView, RequestingClient, WebSocketContext} from "./WebsocketContext"
import uuid from "react-uuid"
import {useNavigate} from "react-router-dom"

export const WebSocketProvider = ({children}: any) => {
    const createUUID = () => {
        const id = uuid()
        localStorage.setItem('clientID', id)
        return id
    }
    const [clientID] = useState(localStorage.getItem('clientID') ?? createUUID())
    const [client, setClient] = useState()
    const [websocket, setWebsocket] = useState<WebSocket>()
    const [nexusList, setNexusList] = useState<Nexus[]>([])
    const [relatedNexus, setRelatedNexus] = useState(null)
    const [htmlGameView, setHtmlGameView] = useState("")
    const [rdyCheck, setRdyCheck] = useState(false)
    const [gameStartingEvent, setGameStartingEvent] = useState<GameStartingEvent>({count: -1, starting:false})
    const [nexusGameView, setNexusGameView] = useState<NexusGameView | null>(null)
    const [requestingClient, setRequestingClient] = useState<RequestingClient | undefined>()
    const [errorMessage, setErrorMessage] = useState("")
    const [roundFinished,setRoundFinished] = useState(false)
    useEffect(() => {
        if(!process.env.REACT_APP_WEBSOCKET_ADDRESS){
            console.error("Missing local properties in .env")
        }
        connectToWebsocket()
    }, [])
    useEffect(() => {
        if(requestingClient?.approvedToJoin !== undefined){
            const reqClient = requestingClient
            sendMessage('approveClientToJoin', {clientID: reqClient.clientID,approveToJoin: reqClient.approvedToJoin})
            setRequestingClient(undefined)
        }
    }, [requestingClient]);

    useEffect(() => {
        setTimeout(()=>setErrorMessage(""),3000)
    }, [errorMessage])
    const navigate = useNavigate()
    const connectToWebsocket = () => {
        const socket = new WebSocket(`ws://${process.env.REACT_APP_WEBSOCKET_ADDRESS}:${process.env.REACT_APP_WEBSOCKET_PORT}?id=${clientID}`)
        socket.onopen = (event) => {
            setWebsocket(socket)
        }
        socket.onclose = (e) => {
            setWebsocket(undefined)
            setErrorMessage('Verbindung zum Gameserver verloren. Bitte Seite neu laden.')
        }
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data)

            switch (message.type) {
                case 'established':
                    setClient(message.data)
                    socket.send(JSON.stringify({type: "requestNexusList", clientID}))
                    break
                case 'nexusList':
                    setRoundFinished(false)
                    setGameStartingEvent({count: -1, starting: false})
                    setRelatedNexus(message.relatedNexus)
                    if (message.data) {
                        const nxList : Nexus[] = message.data
                        const sortedList = nxList
                            .filter(item => !item.started)
                            .sort((a, b) => {
                                if (b.isPublic !== a.isPublic) {
                                    return b.isPublic ? 1 : -1;
                                } else {
                                    return a.createdAt.localeCompare(b.createdAt);
                                }
                            });
                        setNexusList(sortedList)
                    }
                    break
                case 'updateNexus':
                    setNexusGameView(message.data)

                    if(message.data.started){
                        socket.send(JSON.stringify({type: "getRelatedStartWikiPage", clientID, data:{nexusID: message.relatedNexus, title: undefined}}))
                    }
                    if (message.relatedNexus) {
                        setRelatedNexus(message.relatedNexus)
                    }
                    break
                case 'wikiPage':
                    setHtmlGameView(message.data.wikiPage)
                    break
                case 'htmlPage' || 'html_clicked':
                    setHtmlGameView(message.data.html)
                    break
                case 'readyCheck':
                    setRdyCheck(true)
                    break
                case 'readyState':
                    setNexusGameView(message.data)
                    break
                case 'readyCheckFailed':
                    setTimeout(()=>{setRdyCheck(false)},900)
                    break
                case 'gameStartingEvent':
                    if(message.data.starting){
                        setRdyCheck(false)
                    }
                    setGameStartingEvent(message.data)
                    break
                case 'createNexus':
                    navigate('/')
                    break
                case 'nexusDestroyed':
                    setRdyCheck(false)
                    setGameStartingEvent({count: -1, starting: false})
                    socket.send(JSON.stringify({type: "requestNexusList", clientID}))
                    break
                case 'errorMessage':
                    setErrorMessage(message.data)
                    break
                case 'roundStarted':
                    break
                case 'roundFinished':
                    setNexusGameView(message.data)
                    setRoundFinished(true)
                    setRdyCheck(false)
                    break
                case 'clientRequestedJoin':
                    const name = message.data.name
                    const id = message.data.clientID
                    setRequestingClient({name,clientID: id,approvedToJoin: undefined})
                    break
            }
        }
    }

    const sendMessage = (type: string, data: any)=>{
        if(websocket){
            const msg = {
                type,
                clientID,
                data
            }
            websocket.send(JSON.stringify(msg))
        }
    }
    return (
        <WebSocketContext.Provider
            value={{clientID, client, websocket, relatedNexus, nexusList, nexusGameView, htmlGameView, rdyCheck,gameStartingEvent, sendMessage, errorMessage,setErrorMessage, roundFinished, requestingClient, setRequestingClient}}>
            {children}
        </WebSocketContext.Provider>
    )
}