import React, {useContext, useEffect} from "react";
import {NexusGameView, WebSocketContext} from "../../../Websocket/WebsocketContext";
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {Color, ReadyClientComp} from "../ReadyCheckView/ReadyClientComp/ReadyClientComp";
import './GameStartingView.css'
interface Props {
    nexusGameView: NexusGameView
}
export const GameStartingView = ({nexusGameView}: Props) => {

    const context = useContext(WebSocketContext)!
    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    const {  gameStartingEvent} = context

    useEffect(() => {
        if(gameStartingEvent.count <= 3){
            if(gameStartingEvent.count === 0){
                playSound(SoundKey.startingGame,.3)
            }else{
                playSound(SoundKey.startingCounter,.1)
            }
        }

    }, [gameStartingEvent]);


    return (
        <div className={`modal position-absolute`} style={{display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-custom-content modal-bg-cyan box-shd-blue text-blue">
                    <div className="modal-body py-0 p-4">
                        <div className="row mb-4">
                            <div className="d-flex justify-content-center mt-4">
                                {nexusGameView && nexusGameView.connectedClients.map((cl,key) => (
                                    <ReadyClientComp name={cl.name} rdyState={cl.ready} color={Color.blue}/>
                                ))}
                            </div>
                        </div>
                        <div className="row inter-700">
                            <div className={`d-flex justify-content-center fs-4`}>SPIEL STARTET IN</div>
                        </div>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className={`inter-400 fs-count px-2`}>{gameStartingEvent.count}</div>
                                <div className={`px-2`}>sek</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center pb-5 inter-700 mt-2">
                            Das Spiel ist zuende, wenn ein*e Spieler*in die Verbindung gefunden hat oder wird automatisch beendet, wenn die erste Person 10 Clicks aufgebraucht hat.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
