import {Dropdown} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {wikiSummary} from "wikipedia";
import './CreateLobbyInput.css'
import {CubeButton} from "../../../Components/Buttons/CubeButton/CubeButton";

interface Props {
    fetchByPageID: (pageID: number) => Promise<wikiSummary | undefined>
    showDropdown: boolean
    setWikiSummary: Dispatch<SetStateAction<wikiSummary | undefined>>
}
interface SuggestionResult{
    ns: number,
    title: string,
    pageid: number
}
export const CreateLobbyInput = ({ setWikiSummary,fetchByPageID, showDropdown}: Props) => {
    const [inputSearch, setInputSearch] = useState("")
    const [showDropdwn, setShowDropdwn] = useState(false)
    const [suggestionResult, setSuggestionResult] = useState<SuggestionResult[]>(null!)
    const [rndLoading, setRndLoading] = useState(false)
    useEffect(() => {
        if(inputSearch.length > 2){
            fetchSearchTerm().then()
        }
    }, [inputSearch])

    const fetchSearchTerm = async ()=>{
        fetch(`http://${process.env.REACT_APP_WEBSOCKET_ADDRESS}:${process.env.REACT_APP_API_PORT}/suggest?q=${inputSearch}`).then((res)=>{
            res.json().then((result)=>{
                if(result.results){
                    setSuggestionResult(result.results)
                    setShowDropdwn(true)
                }
            })
        })
    }
    const fetchRnd = ()=>{
        if(rndLoading)
            return

        setRndLoading(true)
        fetch(`http://${process.env.REACT_APP_WEBSOCKET_ADDRESS}:${process.env.REACT_APP_API_PORT}/randomSummary`).then((res)=>{
            res.json().then((result)=>{
                if(result){
                    setRndLoading(false)
                    setWikiSummary(result)
                }
            })
        })
    }

    useEffect(() => {
        if(inputSearch.length < 2){
            setShowDropdwn(false)
        }else{
            setShowDropdwn(showDropdown)
        }
    }, [showDropdown]);

    const searchByPageID = async (pageid: number) => {
        const sum = await fetchByPageID(pageid)
        setWikiSummary(sum)
    }

    return (
        <div className="row">
           <div className={`col-2`}>
               {/*<img src="./assets/pngs/icons/dice.png" alt=""/>*/}
               <div onClick={() => fetchRnd()}
                    className={`d-flex justify-content-end`}><CubeButton loading={rndLoading}/></div>
           {/*    <Button disabled={rndLoading} onClick={()=>fetchRnd()} type={'button'} variant={'outline-secondary'}>{!rndLoading?<Dice3/> : <> <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                   <span className="visually-hidden" role="status">Loading...</span></>}</Button>*/}
           </div>
           <div className="col-10 p-0"><input type="text" className="input-form inter-400" id="inputField" placeholder="Suche" onChange={(e) => setInputSearch(e.target.value)}/></div>

        <div className="dropdown">
            <Dropdown show={showDropdwn}>
                <Dropdown.Menu>
                    {suggestionResult ? (
                        suggestionResult.map((result) => (
                            <Dropdown.Item onClick={() => {searchByPageID(result.pageid).then()}} key={result.pageid} href="#">{result.title}</Dropdown.Item>
                        ))
                    ) : null}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>
    )
}