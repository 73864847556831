export const LoadingView = () => {


    return(
        <>
            <div className="min-vh-100 d-flex justify-content-center align-items-center">
                <div className="row w-100">
                    <div className={`arrow-pink-right`} style={{height:"5rem"}}></div>
                    <div className={`pixer fs-1 text-pink text-center mt-5`}>laden...</div>
                </div>
            </div>
        </>
    )
}