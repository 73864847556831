import {Graphics, Stage} from '@pixi/react';
import React, {RefObject, useEffect, useState} from "react";
import {Block} from "./Sprites/Block";
import {ConnectedClient} from "../../../Websocket/WebsocketContext";
import {SoundKey} from "../../../HowlerService/HowlServiceContext";
import {PixiPlugin} from "gsap/PixiPlugin";
import * as PIXI from "pixi.js";
import gsap from "gsap";
import {Name} from "./Sprites/Name";
import {PathName} from "./Sprites/PathName";

PixiPlugin.registerPIXI(PIXI)
gsap.registerPlugin(PixiPlugin)
interface Props {
    canvasRef: RefObject<HTMLDivElement>
    pixiBlockView: boolean
    nexusGameViewClients: ConnectedClient[] | undefined
    playSound : (soundKey: SoundKey, volume?: number) => void
}
export const PixiView = ({canvasRef, nexusGameViewClients, playSound,pixiBlockView}: Props) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [blockHeight, setBlockHeight] = useState(0)
    const [blockPadding, setBlockPadding] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            if (canvasRef.current) {
                setWidth(canvasRef.current.clientWidth);
                setHeight(canvasRef.current.clientHeight);
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])
    const calculateBlockSize = ()=>{
        if(nexusGameViewClients){
            const clients = nexusGameViewClients.length
            const maxSize = width / clients
            const padding = (maxSize * .1)
            const blockWidth = (maxSize - (padding * 2))
            const blockHeight = (9/16) * blockWidth
            const newSize = setBlockSize(blockHeight)
            setBlockPadding(maxSize-newSize)
        }
    }
    const setBlockSize = (blHeight: number)=>{
        const maxHeight = height / 10
        const blockHeight = (h:number)=>{
            let height = h
            if(height > maxHeight){
                height = maxHeight
            }
            setBlockHeight(height)
            return height
        }
        return blockHeight(blHeight)
    }

    useEffect(() => {
        if (canvasRef.current) {
            setWidth(canvasRef.current.clientWidth)
            setHeight(canvasRef.current.clientHeight)
            calculateBlockSize()
        }
    }, [width, height]);
    const options = {
        backgroundAlpha: 0,
        resolution: window.devicePixelRatio,

    };
    const style = {
        width: width,
        height: height,
    };

   return( <Stage width={width} height={height} options={options} style={style}>

           {[1,2,3,4,5,6,7,8,9,10].map((cl,idx)=>{
               const renderLine = (g: PIXI.Graphics) => {
                   const startX = 30;
                   const startY = height - (blockHeight*idx +20) ;
                   const lineLength = width;
                   const lineColor = 'rgba(255,255,255,0.3)'; // Farbcode (rot)
                   const lineWidth = 1;

                   g.lineStyle(lineWidth, lineColor);
                   g.moveTo(startX, startY);
                   g.lineTo(startX + lineLength, startY);
               }


               return <>
               {!pixiBlockView? <Graphics draw={renderLine}/> : null}
                   <Name key={idx} name={cl.toString()} posX={60} posY={height - (blockHeight*idx + 40) -30} width={cl<10?35:45} color={'#FFFFFF7F'}/>
               </>
           })

           }
           {nexusGameViewClients? nexusGameViewClients.map((cl,key)=>{
              return <Name name={cl.name} posX={key * (width /nexusGameViewClients.length)+ (blockPadding/2)} posY={height - 20} width={blockHeight} color={'#FFFFFFFF'}/>
           }) : null}
           {(blockHeight && nexusGameViewClients) ? nexusGameViewClients.map((cl,key)=>{
               return cl.path?.map((path, idx)=>{
                   if(pixiBlockView){
                       return <Block posX={key * (width /nexusGameViewClients.length)+ (blockPadding/2)} posY={height - (blockHeight*idx + 40)} height={blockHeight} padding={blockPadding} posFrom={height - (blockHeight*idx) - 250} playSound={playSound} path={path}/>
                   }else{
                       return <PathName  color={`${key % 2 === 0? '#42FF00' : '#00FFFF'}`} name={path.pathTitle} posY={height - (blockHeight*idx + 40)-30} width={blockHeight} posX={key * (width /nexusGameViewClients.length)+ (blockPadding/2)}/>
                   }
               })
           }) : null}
   </Stage>
   )
}