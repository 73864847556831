import './LobbyCreateButton.css'
import '../button.css'
import {useContext} from "react";
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";

interface Props {
  onClick?: ()=>void
}
export const LobbyCreateButton = ({onClick} : Props) => {

  const howlServiceContext = useContext(HowlServiceContext)!
  const {playSound} = howlServiceContext

  const click = ()=>{
    playSound(SoundKey.click, .2)
    if(onClick){
      onClick()
    }
  }
  return(<div onClick={()=>click()} className={`custom-btn btn--create-lobby d-flex justify-content-center`}></div>)
}